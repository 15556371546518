<template>
  <div class="p-6 all">
    <div>
      <div class="flex items-center mb-12">
        <div
          class="w-2/3 font-c7 text-left text-c34 2xl:text-c42 text-black"
        >
          Tableau de bord
        </div>
      </div>
    </div>

    <div class="flex">
      <div class="w-full">
        <stats />
      </div>

      <!-- <div class="w-1/3 ml-6">
        <abonnement />
      </div> -->
    </div>

    <div
      class="mt-10 rounded-10 h-auto bg-white p-6 flex"
      v-if="user && user.userType === 'ADMIN'"
    >
      <div class="w-full">
        <sfd />
      </div>
    </div>
  </div>
</template>

<script>
import stats from '../../component/cdevs/dashboard/stats'
// import abonnement from '../../component/cdevs/dashboard/abonnementStat'
import sfd from '../../component/cdevs/dashboard/sfdPartner'

export default {
  name: "Index",

  components: {
    stats,
    // abonnement,
    sfd
  },

  data () {
    return {
      user: null
    }
  },

  mounted() {
    if (this.$store.getters.token !== null){
      this.user = this.$store.getters.trackUser.user
    }
  },
}
</script>

<style scoped>
.all{
  min-height: 100vh;
}
</style>
