<template>
  <div class="w-full bord">
    <div class="flex">
      <div class=" w-full">
        <div class="ml-4 text-left text-c16 font-c7 text-83">
          Partenaires
        </div>
      </div>
    </div>

    <div class="mt-10">
      <div
        class="flex items-center pb-4 mt-4 bor"
        v-for="(item, index) in allStat"
        :key="index"
      >
        <div class="w-2/3 flex items-center">
          <div
            class="w-10 h-10 bg-yell rounded-100"
            v-if="item.sfd.pictureId"
          />
          <icon
            :data="icons.entreprise"
            height="20"
            width="20"
            class="cursor-pointer"
            original
          />
          <div class="text-c18 font-c6 ml-3 w-4/5 text-left">
            {{ item.sfd.name }}
          </div>
        </div>

        <div class="w-1/3 flex items-center text-left">
          <icon
            :data="icons.user"
            height="20"
            width="20"
            class="cursor-pointer"
            original
          />
          <div
            class="text-c14 w-4/5 ml-2"
            v-if="item.admin"
          >
            {{ item.admin.firstName }} {{ item.admin.lastName }}
          </div>
          <div
            class="text-c14 w-4/5 ml-2"
            v-if="!item.admin"
          >
            -
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import user from '../../../assets/icons/ic-clientAv.svg'
import entreprise from '../../../assets/icons/ic-entreprise.svg'

export default {
  name: "Index",
  components: {
  },

  props: {
    flow: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        user,
        entreprise
      },
      allStat: null,
    }
  },

  created () {
    this.getBillan()
  },

  methods: {
    getBillan () {
      http.get(apiroutes.baseURL + apiroutes.allSfd)
          .then(response => {
            this.allStat = response
            // console.log('sfd')
            // console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            // console.log(error)
          })
    }
  }
}
</script>

<style scoped>
.bord{
 border-right: 1px solid #DDDDDD;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bor{
  border-bottom: 1px solid #DDDDDD;
}
</style>
